@media (min-width: 830px) {
    .view-content-events {
        column-count: 3;
        margin-top: 24px;
    }
}
.views-widget-filter-field_event_location_tid_i18n {
    display: none;
}
.node-event {
    h2 {
        font-weight: normal;
    }
    .gallery-item.event-display {
        margin: 0;
    }
    &.node-teaser {
        position: relative;
        overflow: hidden;
        .event-title {
            position: absolute;
            bottom: 30px;
            #{$left}: 0;
            #{$right}:0;
            padding: 5px;
            background-color:rgba(13, 177, 243, 0.9);
            text-align: center;
            color: $text;
            transition: transform 1s ease;
            h2 {                
                font-size: 18px;
                margin-bottom: 0;
                a {
                    color: $text;
                }
            }
            time {
                color: white;
            }            
        }
        .event-book {
            display: none;
            position: absolute;
            bottom: 30px; #{$left}: 0; #{$right}:0;
            text-align: center;
        }
        &:hover {
            .event-title {
                transform: translateY(-363px);
            }
            .event-book {
                display: block;
            }
        }
    }
}