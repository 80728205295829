.facetapi-facetapi-links {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0;
    margin: 0;
    border-radius: 7px;
    li {
        list-style: none;
    }
    .btn {
        padding: 4px;
        margin: 4px 7px;
    }
}
.facet-color {
    display: inline-block;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    box-shadow: 0px 0px 5px 0px #bfbfbf;
}