//
// Page Title & Breadcrumbs
// --------------------------------------------------

// Page Title
.page-title {
  width: 100%;
  padding: floor($grid-vertical-step * 2.5) 0; //~36px 0
  border-bottom: 1px solid $border-color;
  background-color: $gray-lighter;
  text-align: center;
  > .container,
  > .container-fluid { display: table; }
  .column {
    display: table-cell;
    vertical-align: middle;
    &:first-child { padding-#{$right}: 20px; }
  }
  h1, h2, h3 {
    margin-bottom: 20px;
  }
  h2, h3 {
    font: {
      size: $font-size-h3;
      weight: normal;
    }
    line-height: $line-height-h3;
  }
  // @media (max-width: $screen-sm) { margin-bottom: ceil($grid-vertical-step * 2.2); }
}

// Breadcrumbs
.breadcrumb {
  display: inline-block;
  padding: 0 25px;
  list-style: none;
  position: relative;
  margin-bottom: 0;
  font-size: $font-size-md;
  @media (max-width: $screen-xl) { padding: 0 10px }
  > li {
    display: inline-block;
    color: $gray;
    cursor: default;
    vertical-align: middle;
    > a {
      transition: color .25s;
      color: $gray-dark;
      text-decoration: none;
      &:hover { color: $brand-primary; }
    }
  }
  .delimiter {
    color: $gray;
  }
}

// Media query (max-width: 768px)
@media (max-width: $screen-md) {
  .page-title {
    > .container,
    > .container-fluid { display: block; }
  }
}
