//
// Gallery (Photoswipe)
// --------------------------------------------------

// Gallery Item
.gallery-item {
  margin-bottom: 30px;
  position: relative;
  > a {
    > img {
      display: block;
      max-height: 90vh;
      margin: 0 auto;
    }
    &::before {
      @include overlay-block(1, $black-color, 0);
      transition: opacity .3s;
    }
    &::after {
      display: block;
      position: absolute;
      top: 50%;
      #{$left}: 0;
      width: 100%;
      margin-top: -24px;
      transform: translateY(15px);
      transition: all .35s;
      color: $white-color;
      text-align: center;
      opacity: 0;
      z-index: 5;
    }
    &:hover {
      &::before { opacity: .45; }
      &::after {
        transform: translateY(0);
        opacity: 1;
      }
    }
    &[data-type='video'] {
      &::after {
        #{$left}: 50%;
        width: 46px;
        height: 46px;
        margin: {
          top: -22px;
          #{$left}: -22px;
        }
        padding-#{$left}: 5px;
        transform: none;
        border-radius: 50%;
        background: {
          position: center;
          color: $red-color;
          image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEMAAABDCAYAAADHyrhzAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAATRJREFUeNrs3MkKwzAMBNB40P//skqhUOgS4tiytYyOJbXDY6KT5aaqx0d9/fCqdiQvdDyrJ1DlMNKjYOC/6VAwYQ0lRsKUYPJ6oVFgtG5IFBivr8QImhIs3Ms9CjbsqcQIkBJs3t8VCpy8hwsUOEuqEsNJSjxibEPxjLEcJQLGsn4SCcM8JdEwTFGiYpigRMeY2k+yYExJSSaMYZSMGLc/ncwY3SnJjtGFUgXjEko1jNN+UhXjJ0hlDCaDGMQgRk81YvyBeJYQoR7GpWObQoQaPaP7EK8QISfG8HFuECJPMqYe7hcixMUwHfMAIWIlY9nQjxDBN8a28S8Qwl8yXAwDChH2Y7gcCwUh9iTD/ZCwEGHdZxJqZFyIYIcR+vIAIcLcnpHmKgkhwhhG2ktFhAjveggwAHwCOZlRzitRAAAAAElFTkSuQmCC);
          repeat: no-repeat;
          size: 10px 10px;
        }
        box-shadow: 0 4px 15px 0 rgba($black-color, .25);
        content: '';
        opacity: 1;
      }
    }
  }
  .caption { display: none; }
  &.no-hover-effect > a::before { display: none; }
}

.grid-no-gap .gallery-item {
  margin-bottom: 0;
  > a { border-radius: 0; }
}
.owl-carousel .gallery-item { margin-bottom: 0; }

// Video Button
.video-btn {
  margin-bottom: 0;
  > a {
    display: inline-block;
    width: 80px;
    height: 80px;
    border: 3px $white-color solid;
    border-radius: 50%;
    background: {
      position: 54% center;
      color: $red-color;
      image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEMAAABDCAYAAADHyrhzAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAATRJREFUeNrs3MkKwzAMBNB40P//skqhUOgS4tiytYyOJbXDY6KT5aaqx0d9/fCqdiQvdDyrJ1DlMNKjYOC/6VAwYQ0lRsKUYPJ6oVFgtG5IFBivr8QImhIs3Ms9CjbsqcQIkBJs3t8VCpy8hwsUOEuqEsNJSjxibEPxjLEcJQLGsn4SCcM8JdEwTFGiYpigRMeY2k+yYExJSSaMYZSMGLc/ncwY3SnJjtGFUgXjEko1jNN+UhXjJ0hlDCaDGMQgRk81YvyBeJYQoR7GpWObQoQaPaP7EK8QISfG8HFuECJPMqYe7hcixMUwHfMAIWIlY9nQjxDBN8a28S8Qwl8yXAwDChH2Y7gcCwUh9iTD/ZCwEGHdZxJqZFyIYIcR+vIAIcLcnpHmKgkhwhhG2ktFhAjveggwAHwCOZlRzitRAAAAAElFTkSuQmCC);
      repeat: no-repeat;
      size: 15px 15px;
    }
    box-shadow: 0 7px 22px -5px rgba(0, 0, 0, .2);
    &::before,
    &::after { display: none; }
  }
  .caption {
    display: block;
    padding-top: 10px;
  }
}


// Photoswipe
.pswp__zoom-wrap {
  text-align: center;
  &::before {
    display: inline-block;
    height: 100%;
    content: '';
    vertical-align: middle;
  }
}
.media-element-container {
  margin: 0 0 20px;
}
.wrapper {
  display: inline-block;
  position: relative;
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
  line-height: 0;
  text-align: $left;
  vertical-align: middle;
  z-index: 1045;
}
.video-wrapper {
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 25px;
  padding-bottom: 56.25%; /* 16:9 */
  iframe {
    position: absolute;
    top: 0;
    #{$left}: 0;
    width: 100%;
    height: 100%;
  }
}
video {
  width: 100% !important;
  height: auto !important;
}
.file-video {
  flex: 1;
}
.pswp__caption__center {
  padding: 20px 10px;
  font: {
    size: $font-size-sm;
    weight: 500;
  }
  text-align: center;
}

// Categories
.sp-categories {
  > a, i {
    display: inline-block;
    margin-#{$right}: 3px;
    color: $gray;
    font-size: $font-size-sm;
    text-decoration: none;
    vertical-align: middle;
  }
  > a { transition: color .25s; }
  > a:hover { color: $nav-link-hover-color; }
}