//
// Off-Canvas Menu
// --------------------------------------------------
.offcanvas-wrapper {
  position: relative;
  min-height: 100vh;
  background-color: $body-bg;
  z-index: 10;
}
.offcanvas-container {
  position: fixed;
  top: 0;
  #{$left}: 0;
  width: $offcanvas-width;
  height: 100%;
  background-color: $gray-darker;
  box-shadow: inset -4px 0 17px 0 rgba(0, 0, 0, .35);
  visibility: hidden;
  z-index: 1;
  overflow-y: auto;
  &.active { visibility: visible; }
}
.offcanvas-header {
  padding: 28px 20px;
  border-bottom: 1px solid $border-light-color;
  .offcanvas-title {
    margin-bottom: 0;
    color: rgba($white-color, .5);
    font: {
      size: $font-size-base;
      weight: $font-weight-headings;
    }
  }
}
.account-link {
  display: table;
  width: 100%;
  padding: 20px 18px;
  transition: background-color .3s;
  border-bottom: 1px solid $border-light-color;
  background-color: darken($gray-darker, 3%);
  text-decoration: none;
  .user-ava,
  .user-info {
    display: table-cell;
    vertical-align: middle;
  }
  .user-ava {
    width: 48px;
    > img {
      display: block;
      width: 48px;
      padding: 3px;
      border: 1px solid $border-light-color;
      border-radius: 50%;
    }
  }
  .user-info {
    padding-#{$left}: 8px;
    > .user-name {
      margin-bottom: 2px;
      color: $white-color;
    }
    > span { display: block; }
  }
  &:hover { background-color: lighten($gray-darker, 1%); }
}
.offcanvas-menu {
  @extend %offcanvas-menu;
}

// Site Backdrop
.site-backdrop {
  @include overlay-block(9980, $white-color, 0);
  position: fixed;
  transition: opacity .35s, visibility .35s;
  cursor: pointer;
  visibility: hidden;
}

// Off-Canvas open
.no-csstransforms3d {
  .offcanvas-wrapper,
  .navbar, .topbar {
    transition: $left .4s ease-in-out, background-color .2s;
  }
  .site-backdrop {
    transition: $left .4s ease-in-out, opacity .35s, visibility .35s;
  }
  .offcanvas-open {
    .offcanvas-wrapper,
    .site-backdrop,
    .navbar, .topbar { #{$left}: 290px; }
    .site-backdrop {
      opacity: .2;
      visibility: visible;
    }
  }
}
.csstransforms3d {
  .offcanvas-wrapper,
  .navbar, .topbar {
    transition: transform .4s ease-in-out, background-color .2s;
  }
  .site-backdrop {
    transition: transform .4s ease-in-out, opacity .35s, visibility .35s;
  }
  .offcanvas-open {
    position: fixed;
    .offcanvas-wrapper,
    .site-backdrop,
    .navbar, .topbar {
      transform: translate3d(#{$pos}290px, 0, 0)!important;
    }
    .site-backdrop {
      opacity: .2;
      visibility: visible;
    }
  }
}
