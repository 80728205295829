//
// Modal
// --------------------------------------------------

.modal { z-index: 9200; }
.modal-content {
  border-radius: $border-radius-lg;
  border-color: $border-color;
}
.modal-header,
.modal-body,
.modal-footer {
  padding: {
    #{$right}: 20px;
    #{$left}: 20px;
  }
}
.modal-footer {
  padding: {
    top: floor($grid-vertical-step / 2);
    bottom: floor($grid-vertical-step / 2);
  }
  .btn {
    margin: {
      #{$right}: 0;
      #{$left}: 12px;
    }
  }
}
.modal-open.hasScrollbar .navbar-stuck {
  width: calc(100% - 15px);
}
.modal-backdrop { z-index: 9100; }

/* The Modal (background) */
.virtualRoom {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  @media (max-width: 640px) {
    grid-template-rows: 1fr 60px;
  }
  @media (min-width: 641px) {
    grid-template-columns: 200px 1fr;
  }
  z-index: 9500; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: black;
}
.room__sidebar {
  display: grid;
  place-items: center;
  @media (max-width: 640px) {
    grid-template-columns: 134px 1fr;
    max-width: 100%;
    overflow-x: auto;
    grid-gap: 2px;
    padding: 0 6px;
  }
  @media (min-width: 641px) {
    grid-row-start: 1;
    grid-column-start: 1;
    grid-template-rows: 150px 150px auto;
  }
  background-color: black;
}
.original__bgs {
  display: grid;
  @media (max-width: 640px) {
    grid-gap: 4px;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    padding: 0;
    img {
      height: 50px;
      width: auto;
    }
  }
  grid-gap: 20px;
}
.original__frames {
  @media (max-width: 640px) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 3px;
    padding: 3px;
  }
}
.room__bg {
  @media (min-width: 641px) {
    grid-column-start: 2;
  }
  background: black url(/files/bg1.jpg) center center no-repeat;
  background-size: cover;
}
.roomImg {
  width: 100vw;
  height: 100vh;
  object-fit: cover;
}


/* Modal Content/Box */
.roomContent {
  position: absolute;
  left: 35%;
  top: 35%;
  z-index: 9600;
}
/* The Close Button */
.closeRoom {
  color: #3ba1c3;
  position: absolute;
  top: 0;
  right: 20px;
  font-size: 40px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}
#onTheWall {
  pointer-events: none;
}
.frames {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 15px;
  place-items: center;
  padding: 15px;
}
button#roomBtn {
  max-width: 300px;
}
#frame {
  pointer-events: none;
  box-shadow:0 0 5px 0 rgba(0,0,0,.25) inset, 0 5px 10px 5px rgba(0,0,0,.25);
}
#dragme {
  @media (max-width: 640px) {
    display: none;
  }
  background-color: #2cc6e6;
}

.frames > img {
  border: 1px solid black;
}

// Frames
.frame1{
  border:solid 2vmin rgb(32, 32, 32);
  border-bottom-color:rgb(15, 15, 15);
  border-left-color:rgb(32, 32, 32);
  border-radius:2px;
  border-right-color:rgb(32, 32, 32);
  border-top-color:rgb(15, 15, 15);
}
.frame2{
  border:solid 2vmin #eee;
  border-bottom-color:#fff;
  border-left-color:#eee;
  border-radius:2px;
  border-right-color:#eee;
  border-top-color:#ddd;
}
.frame3 {
  background-color:#ddc;
  border:solid 5vmin #eee;
  border-bottom-color:#fff;
  border-left-color:#eee;
  border-radius:2px;
  border-right-color:#eee;
  border-top-color:#ddd;
  display:inline-block;
  // margin:10vh 10vw;
  // height:80vh;
  // padding:8vmin;
  position:relative;
  text-align:center;
  &:before {
    border-radius:2px;
    bottom:-2vmin;
    box-shadow:0 2px 5px 0 rgba(0,0,0,.25) inset;
    content:"";
    left:-2vmin;
    position:absolute;
    right:-2vmin;
    top:-2vmin;
  }
  &:after {
    border-radius:2px;
    bottom:-2.5vmin;
    box-shadow: 0 2px 5px 0 rgba(0,0,0,.25);
    content:"";
    left:-2.5vmin;
    position:absolute;
    right:-2.5vmin;
    top:-2.5vmin;
  }
  img {
    border:solid 2px;
    border-bottom-color:#ffe;
    border-left-color:#eed;
    border-right-color:#eed;
    border-top-color:#ccb;
    max-height:100%;
    max-width:100%;
  }
}

