.artist {
    margin-bottom: 40px;
    @media (max-width: 640px) {
        text-align: center;
    }
    clear:both;
    overflow: hidden;
    .btn {
        margin-top: 93px;
        margin-#{$left}: 20px;
    }
}
.header-artist {
    margin-bottom: 25px;
    h3 {
        font-size: 26px;
        font-weight: normal;
    }
    a {
        transition: color .3s;
        color: #232323;
        text-decoration: none;
        &:hover {
            color: $brand-primary;
        }
    }
    & > * {
        display: table-cell;
        padding-#{$right}: 30px;
    }
}
.art-landscape {
    display: inline-block;
    margin-right: 10px;
    margin-bottom: 10px;
    vertical-align: top;
    h3 {
        margin: 0;
        font-size: 16px;
        font-weight: normal;
    }
    a {
        transition: color .3s;
        color: #232323;
        text-decoration: none;
    }
    img {
        height: 240px;
        width: auto;
    }
    .col-8 {
        text-align: $left;
        font-size: 13px;
        color: #999 !important;
    }
    .col-4 {
        text-align: $right;
    }
}
@media (min-width: 641px) {
    .first-landscape {
        float: $left;
        margin-#{$right}: 3px;
        img {
            height: 530px;
        }
    }
}
