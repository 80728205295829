//
// Carousel (based on OWL Carousel Plugin)
// --------------------------------------------------

// Base class
.owl-carousel {
  // display: none;
  position: relative;
  width: 100%;
  z-index: 1;
  -webkit-tap-highlight-color: transparent;
  .owl-stage {
    position: relative;
    -ms-touch-action: pan-Y;
    -moz-backface-visibility: hidden; /* fix firefox animation glitch */
	}
  .owl-stage::after {
    display: block;
    height: 0;
    clear: both;
    line-height: 0;
    content: '.';
    visibility: hidden;
	}
  .owl-stage-outer {
    position: relative;
    transform: translate3d(0, 0, 0);
    overflow: hidden;
	}
  .owl-wrapper, .owl-item {
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
	}
  .owl-item {
    position: relative;
    min-height: 1px;
    float: $left;
    backface-visibility: hidden;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    .owl-lazy {
      transition: opacity 400ms ease;
      opacity: 0;
    }

    // Layers animation
    .from-top, .from-bottom,
    .from-#{$left}, .from-#{$right},
    .scale-up, .scale-down {
      transition: all 1.5s .3s ease-in-out;
      opacity: 0;
      backface-visibility: hidden;
    }
    .from-top {
      transform: translateY(-45px);
    }
    .from-bottom {
      transform: translateY(45px);
    }
    .from-#{$left} {
      transform: translateX(-45px);
    }
    .from-#{$right} {
      transform: translateX(45px);
    }
    .scale-up {
      transform: scale(0.8);
    }
    .scale-down {
      transform: scale(1.2);
    }
    .delay-1 {
      transition-delay: .5s;
    }
    .delay-2 {
      transition-delay: .7s;
    }
    .delay-3 {
      transition-delay: .9s;
    }
    .delay-4 {
      transition-delay: 1.1s;
    }
    &.active {
      .from-top, .from-bottom {
        transform: translateY(0);
        opacity: 1;
      }
      .from-#{$left}, .from-#{$right} {
        transform: translateX(0);
        opacity: 1;
      }
      .scale-up, .scale-down {
        transform: scale(1);
        opacity: 1;
      }
    }
	}
  .owl-item > img {
    display: block;
    width: 100%;
    &.owl-lazy { transform-style: preserve-3d; }
	}
  .owl-nav.disabled,
  .owl-dots.disabled {
    display: none;
	}
  .owl-nav .owl-prev,
  .owl-nav .owl-next,
  .owl-dot {
    cursor: pointer;
    cursor: hand;
    user-select: none;
	}
  &.owl-loaded { display: block; }
  &.owl-loading {
    display: block;
    opacity: 0;
	}
  &.owl-hidden { opacity: 0; }
  &.owl-refresh .owl-item { visibility: hidden; }
  &.owl-drag .owl-item {
    user-select: none;
	}
  &.owl-grab {
    cursor: move;
    cursor: grab;
	}

  // Owl Carousel - Animate Plugin
  .animated {
    animation-duration: 1000ms;
    animation-fill-mode: both;
	}
  .owl-animated-in { z-index: 0; }
  .owl-animated-out { z-index: 1; }
  .fadeOut { animation-name: fadeOut; }

  // Dots
  .owl-dots {
    display: block;
    width: 100%;
    margin-top: 18px;
    text-align: center;
    .owl-dot {
      background: 0 0;
      color: inherit;
      border: none;
      padding: 0!important;
      font: inherit;
      span {
        display: inline-block;
        width: $carousel-dots-size;
        height: $carousel-dots-size;
        background-color: $brand-danger;
        padding: 0;
        margin: 0 6px;
        transition: opacity .25s;
        border-radius: 50%;
        opacity: .33;
      }      
      &.active { 
        span {
          opacity: 1; 
        }
      }
    }
  }

  // Navs
  .owl-prev,
  .owl-next {
    display: block;
    position: absolute;
    top: 45%;
    width: $carousel-navs-size;
    height: $carousel-navs-size;
    margin-top: -(($carousel-navs-size / 2) + 20);
    transition: opacity .3s;
    border: 1px solid $border-color;
    border-radius: $border-radius-base;
    background: $carousel-navs-bg center no-repeat;
    background-size: ($carousel-navs-size / 2) ($carousel-navs-size / 2);
    opacity: .75;
    &:hover { opacity: 1; }
  }
  .owl-prev {
    #{$left}: 0;
    [dir=ltr] & {
      background-image: url('data:image/svg+xml;utf8,<svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32"><path d="M20 25.333c-0.4 0-0.667-0.133-0.933-0.4l-8-8c-0.533-0.533-0.533-1.333 0-1.867l8-8c0.533-0.533 1.333-0.533 1.867 0s0.533 1.333 0 1.867l-7.067 7.067 7.067 7.067c0.533 0.533 0.533 1.333 0 1.867-0.267 0.267-0.533 0.4-0.933 0.4z"></path></svg>');
    }
    [dir=rtl] & {
      background-image: url('data:image/svg+xml;utf8,<svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32"><path d="M12 25.333c-0.4 0-0.667-0.133-0.933-0.4-0.533-0.533-0.533-1.333 0-1.867l7.067-7.067-7.067-7.067c-0.533-0.533-0.533-1.333 0-1.867s1.333-0.533 1.867 0l8 8c0.533 0.533 0.533 1.333 0 1.867l-8 8c-0.267 0.267-0.533 0.4-0.933 0.4z"></path></svg>');
    }
  }
  .owl-next {
    #{$right}: 0;
    [dir=rtl] & {
      background-image: url('data:image/svg+xml;utf8,<svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32"><path d="M20 25.333c-0.4 0-0.667-0.133-0.933-0.4l-8-8c-0.533-0.533-0.533-1.333 0-1.867l8-8c0.533-0.533 1.333-0.533 1.867 0s0.533 1.333 0 1.867l-7.067 7.067 7.067 7.067c0.533 0.533 0.533 1.333 0 1.867-0.267 0.267-0.533 0.4-0.933 0.4z"></path></svg>');
    }
    [dir=ltr] & {
      background-image: url('data:image/svg+xml;utf8,<svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32"><path d="M12 25.333c-0.4 0-0.667-0.133-0.933-0.4-0.533-0.533-0.533-1.333 0-1.867l7.067-7.067-7.067-7.067c-0.533-0.533-0.533-1.333 0-1.867s1.333-0.533 1.867 0l8 8c0.533 0.533 0.533 1.333 0 1.867l-8 8c-0.267 0.267-0.533 0.4-0.933 0.4z"></path></svg>');
    }
  }
  &.large-controls {
    .owl-prev,
    .owl-next {
      width: $btn-lg-height;
      height: $btn-lg-height;
      margin-top: -(($btn-lg-height / 2) + 20);
      border-radius: $border-radius-lg;
    }
    .owl-prev { #{$left}: 30px; }
    .owl-next { #{$right}: 30px; }
  }
  &.dots-inside {
    .owl-dots {
      position: absolute;
      bottom: 0;
      margin: 0;
      padding-bottom: $grid-vertical-step;
      .owl-dot {
        background-color: transparent;
        opacity: .5;
        &.active { opacity: 1; }
      }
    }
    .owl-prev,
    .owl-next { margin-top: -($carousel-navs-size / 2); }
    &.large-controls {
      .owl-prev,
      .owl-next { margin-top: -($btn-lg-height / 2); }
    }
  }

  // Widget inside
  .widget {
    margin-bottom: 0;
    &.widget-featured-posts > .entry { margin-bottom: 0; }
  }
}

// No Js
.no-js .owl-carousel { display: block; }

@keyframes fadeOut {
  0% { opacity: 1; }
  100% { opacity: 0; }
}

//	Owl Carousel - Auto Height Plugin
.owl-height { transition: height 500ms ease-in-out; }

// Hero Slider
.hero-slider {
  width: 100%;
  min-height: $hero-slider-min-height;
  @media (max-width: $screen-md) {
    min-height: calc(100vh - 78px);
  }
  background: {
    position: center;
    color: $white-color;
    repeat: no-repeat;
    size: cover;
  }
  // box-shadow: $navbar-stuck-shadow;
  border-bottom: 1px solid #e5e5e5;
  overflow: hidden;
  @media (min-width: 640px) {
    > .owl-carousel { max-height: 75vh; }
  }
  @media (max-width: 1100px) {
    min-height: $hero-slider-min-height - 150;
    > .owl-carousel { min-height: $hero-slider-min-height - 150; }
  }
}

.slider-item {
  @media (max-width: 767px) {
    height: 512px;
  }
  @media (min-width: 640px) {
    min-height: 600px;
  }
}

.hero-slider {
  > .owl-carousel {
    min-height: $hero-slider-min-height;
    .owl-prev,
    .owl-next {
      top: auto;
      bottom: 18px;
      #{$left}: auto;
      width: 46px;
      height: 46px;
      border: 0;
      border-radius: 0;
      &:hover { background-color: $gray-lighter; }
    }
    .owl-prev {
      #{$right}: ($carousel-navs-size + 18);
      border: {
        top: 1px solid $border-color;
        bottom: 1px solid $border-color;
      }
    }
    .owl-next {
      #{$right}: 18px;
      border: 1px solid $border-color;
      border-top-#{$right}-radius: $border-radius-base;
      border-bottom-#{$right}-radius: $border-radius-base;
    }
    &.dots-inside {
      .owl-dots {
        @media (min-width: $screen-md) {
          #{$right}: (($carousel-navs-size * 2) + 18);
          bottom: 18px;
          width: auto;
          height: $carousel-navs-size;
          padding: 10px 16px 14px;
        }       
        background-color: transparent;
      }
    }
  }
}

.artist-portfolio {
  position: relative;
}
.all-artwork {
  position: absolute;
  z-index:2;
  #{$right}: calc(50% - 58px);
  bottom: 18px;
  margin: 0;
}
