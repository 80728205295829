.artist-detail {
  display:table;
  width: 100%;
  & > * {
    display: table-cell;
  }
}
.artists {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 20px;
}
.card-artist {
  box-shadow: 0 10px 50px -9px rgba(0,0,0,.5);
  .card-body {
    padding-top: 0;
  }
}
.card-artist-header {
  display: block;
  float: $left;
  margin-#{$right}: 1rem;
  margin-bottom: 1rem;
  border-radius: 50%;
}
.artist-more-originals {
  position: absolute;
  bottom: 40px;
  right: 40px;
  padding: 0 8px;
  font-size: 2em;
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
}
.artist--title {
  font-size: 18px;
  font-weight: 400;
  line-height: 1.4;
  margin-bottom: 0;
}
.art-pill {  
  color: #fff;
  font-size: 9px;
  html[dir=rtl] & {
    font-size: 11px;
  }
  text-transform: uppercase;
  background-color: $gray-dark;
  padding: 3px 8px;
  border-radius: 15px;
  margin: {
    top: 0;
    #{$right}: 2px;
    bottom: 2px;
    #{$left}: 0;
  }
  border-radius: 15px;
  white-space: nowrap;
  transition: all .3s ease;
  text-decoration: none;  
  &:hover { 
    color: #fff;
    background-color: darken($gray-dark, 4%); 
  }  
}
.art-pill-beyond {
  background-color: $brand-primary;
  &:hover { background-color: darken($brand-primary, 4%); }  
}
.artist-portfolio.card-columns {
  column-count: 1;
  @media (min-width: $screen-sm) {
    column-count: 2;
  }
  @media (min-width: $screen-lg) {
    column-count: 3;
  }
  @media (min-width: $screen-xl) {
    column-count: 4;
  }
  @media (min-width: $screen-xxl) {
    column-count: 5;
  }
}
.events-list { 
  a {
    text-decoration: none;
    display: flex;
    margin-bottom: 12px;
  }
  span {
    color: $gray-dark;
  }
}
