#commerce-checkout-form-checkout {
    display: flex;
    flex-wrap: wrap;
    > * {
        flex : 1 0 auto;        
        margin-bottom: 40px;
    }
    label {
        margin-bottom: 8px;
        padding-#{$left}: 18px;
        font-size: 13px;
        font-weight: 500;
        cursor: pointer;
    }    
}
.customer_profile_shipping {
    padding-#{$right}: 40px;
}
#edit-account, #edit-buttons {
    width: 100%;
}

// Adresses clientes
.field-type-addressfield {
    font-style: italic;
}
.commerce-paypal-icon {
    position: static;
}
